import moment from "moment";

const state = {
  snackbarDisplayed: false,
  snackbarText: "",
  drawerOpen: false,
  currentDate: "",
  CEWarnClosed: false,
  DPWarnClosed: false,
  deferredPrompt: null,
  navBarVisible: false,
};

const getters = {
  getSnackbarDisplayed(state) {
    return state.snackbarDisplayed;
  },
  getSnackbarText(state) {
    return state.snackbarText;
  },

  getDrawerOpen(state) {
    return state.drawerOpen;
  },
  getCurrentDate() {
    return moment().startOf('day');
  },
  getCurrentDateString(state) {
    return moment(state.currentDate).format("YYYY-MM-DD HH:mm");
  },
  getCEWarnClosed(state) {
    return state.CEWarnClosed;
  },
  getDeferredPrompt(state) {
    return state.deferredPrompt;
  },
  getDPWarnClosed(state) {
    return state.DPWarnClosed;
  },
  getNavBarVisible(state) {
    return state.navBarVisible;
  },
};

const mutations = {
  setSnackbarDisplayed(state, value) {
    state.snackbarDisplayed = value;
  },
  setSnackbarText(state, value) {
    state.snackbarText = value;
  },

  setDrawerOpen(state, value) {
    state.drawerOpen = value;
  },
  setCurrentTimeInterval(state) {
    state.currentDate = moment();

    state.currentTimeInterval = setInterval(() => {
      state.currentDate = moment();
    }, 1000 * 60);
  },
  clearCurrentTimeInterval(state) {
    state.currentTimeInterval = null;
  },
  setCEWarnClosed(state, value) {
    state.CEWarnClosed = value;
  },
  setDeferredPrompt(state, value) {
    state.deferredPrompt = value;
  },
  setDPWarnClosed(state, value) {
    state.DPWarnClosed = value;
  },
  setNavBarVisible(state, value) {
    state.navBarVisible = value;
    // When hiding sidebar, close it as well
    if (!value) {
      state.drawerOpen = value;
    }
  },
};

const actions = {
  showSnackbar(context, text) {
    context.commit("setSnackbarText", text);
    context.commit("setSnackbarDisplayed", true);
  },
  startCurrentTimeInterval(context) {
    context.commit("setCurrentTimeInterval");
  },
  stopCurrentTimeInterval(context) {
    context.commit("clearCurrentTimeInterval");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
