import { db } from "../../modules/firestore";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // ======================
  // Data Migration methods
  // ======================
  runMigration() {
    db.collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((user) => {
          var userTasks = [];
          db.collection("users/" + user.id + "/tasks")
            .get()
            .then((qs2) => {
              qs2.forEach((task) => {
                // Create Parent Task Id
                if (task.data().labels && task.data().labels.length > 0) {
                  const taskIndex = task
                    .data()
                    .labels.findIndex((label) => label.type == "task");
                  if (taskIndex > -1) {
                    console.log("migrating " + task.id);

                    db.collection("users/" + user.id + "/tasks")
                      .doc(task.id)
                      .set(
                        { parentTaskId: task.data().labels[taskIndex].id },
                        { merge: true }
                      );

                    // Add to full list of tasks array
                    userTasks.push({
                      id: task.id,
                      parentTaskId: task.data().labels[taskIndex].id,
                    });
                  } else {
                    userTasks.push({
                      id: task.id,
                      parentTaskId: -1,
                    });
                  }
                } else {
                  userTasks.push({
                    id: task.id,
                    parentTaskId: -1,
                  });
                }
                // Delete Label
                db.collection("users/" + user.id + "/tasks")
                  .doc(task.id)
                  .update({
                    labels: [],
                  });
              });

              userTasks.forEach((uTask) => {
                console.log("processing " + uTask.id);

                var parentTask;
                var parentTaskId = uTask.parentTaskId;

                if (parentTaskId != -1) {
                  while (parentTaskId != -1) {
                    parentTask = userTasks.find(
                      (pTask) => pTask.id == parentTaskId
                    );
                    if (parentTask) {
                      parentTaskId = parentTask.parentTaskId ?? -1;
                    } else {
                      console.log("cant find ", parentTaskId);
                      parentTaskId = -1;
                    }
                  }
                  console.log(
                    "setting task " + uTask.id + " to " + parentTask.id
                  );
                  db.collection("users/" + user.id + "/tasks")
                    .doc(uTask.id)
                    .set({ rootTaskId: parentTask.id }, { merge: true });
                }
              });
            });
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
